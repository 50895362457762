import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationPL from './locales/pl/translation.json';
import translationDE from './locales/de/translation.json';

const resources = {
    en: {
      translation: translationEN
    },
    pl: {
      translation: translationPL
    },
    de: {
      translation: translationDE
    }
  };

  const savedLanguage = localStorage.getItem('i18nextLng') || 'pl';


  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: savedLanguage , // default language
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false // React already does escaping
      }
    });

  export default i18n;