import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Figure from 'react-bootstrap/Figure';


function MachineParkPage() {
  const { t } = useTranslation();

  return (
    <Container className="my-3 gx-0" >
      <h2 className="text-center">{t('maszyny')}</h2>
      <Row className="text-center my-3 gx-0 d-flex justify-content-center">
        <Col xs={12} md={6}>
          <Figure>
            <Figure.Image src={require('../static/machines/gilotyna.jpeg')} rounded width="100%" alt="gilotyna"/>
            <Figure.Caption>
              {t('gilotyna')}
            </Figure.Caption>
          </Figure>
        </Col>
        {/* <Col xs={12} md={6}>
          <Figure>
            <Figure.Image src={require('../static/machines/koparka.jpeg')} rounded width="100%" alt="koparka"/>
            <Figure.Caption>
              {t('koparka')}
            </Figure.Caption>
          </Figure>
        </Col> */}
        <Col xs={12} md={6}>
          <Figure>
            <Figure.Image src={require('../static/machines/ładowarka teleskopowa (2).jpeg')} rounded width="50%" alt="ladowarka"/>
            <Figure.Image src={require('../static/machines/ładowarka teleskopowa.jpeg')} rounded width="50%" alt="ladowarka"/>
            <Figure.Caption>
              {t('ladowarka')}
            </Figure.Caption>
          </Figure>
        </Col>
        <Col xs={8} md={4}>
          <Figure>
            <Figure.Image src={require('../static/machines/spawarki TIG MAG.jpeg')} rounded width="100%" alt="spawarki"/>
            <Figure.Caption>
              {t('spawarki')}
            </Figure.Caption>
          </Figure>
        </Col>
        {/* <Col xs={12} md={6}>
          <Figure>
            <Figure.Image src={require('../static/machines/wózek widłowy.jpeg')} rounded width="100%" alt="wozek"/>
            <Figure.Caption>
              {t('widlowy')}
            </Figure.Caption>
          </Figure>
        </Col> */}
        <Col xs={12} md={6}>
          <Figure>
            <Figure.Image src={require('../static/machines/wypalarka plazmowa.jpeg')} rounded width="100%" alt="wypalarka"/>
            <Figure.Caption>
              {t('wypalarka')}
            </Figure.Caption>
          </Figure>
        </Col>
        <Col xs={24} md={16}>
          <Figure>
            <Figure.Image src={require('../static/machines/walce do blach (2).jpeg')} rounded width="100%" alt="walce"/> <br/>
            <Figure.Image src={require('../static/machines/walce do blach.jpeg')} rounded width="100%" alt="walce"/>
            <Figure.Caption>
              {t('walce')}
            </Figure.Caption>
          </Figure>
        </Col>
      </Row>
    </Container>
  );
}

export default MachineParkPage;
