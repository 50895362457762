import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Figure from 'react-bootstrap/Figure';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';


function CertificatesPage() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  let cert1090Path;
  try {
    cert1090Path = require(`../static/certs/1090_${currentLanguage}.jpg`);
  } catch (error) {
    cert1090Path = require('../static/certs/1090_en.jpg');
  }
  let cert3834Path;
  try {
    cert3834Path = require(`../static/certs/3834_${currentLanguage}.jpg`);
  } catch (error) {
    cert3834Path = require('../static/certs/3834_en.jpg');
  }

  const images = [
    { src: cert1090Path },
    { src: cert3834Path }
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };


  return (
    <Container className="my-3 gx-0" >
      <h2 className="text-center">{t('certyfikaty')}</h2>
      <Row className="text-center my-3 gx-0 d-flex justify-content-center">
        <Col md={6}>
          <Figure>
            <Figure.Image
              src={cert1090Path}
              rounded
              width="100%"
              alt="1090 cert"
              onClick={() => openLightbox(0)}
              style={{ cursor: 'pointer' }}
            />
          </Figure>
        </Col>
        <Col md={6}>
          <Figure>
            <Figure.Image
              src={cert3834Path}
              rounded
              width="100%"
              alt="3834 cert"
              onClick={() => openLightbox(1)}
              style={{ cursor: 'pointer' }}
            />
          </Figure>
        </Col>
      </Row>
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images}
          index={photoIndex}
          onIndexChange={setPhotoIndex}
        />
      )}
    </Container>
  );
}

export default CertificatesPage;
