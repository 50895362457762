import React from 'react';
import { useTranslation } from 'react-i18next';
import {Button, ButtonGroup} from 'react-bootstrap';


function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang); // Save the selected language to local storage
  };

  return (
    <ButtonGroup aria-label="Language switcher">
      <Button
        variant={currentLanguage === 'en' ? 'dark' : 'outline-dark'}
        onClick={() => changeLanguage('en')}
      >
        EN
      </Button>
      <Button
        variant={currentLanguage === 'pl' ? 'dark' : 'outline-dark'}
        onClick={() => changeLanguage('pl')}
      >
        PL
      </Button>
      <Button
        variant={currentLanguage === 'de' ? 'dark' : 'outline-dark'}
        onClick={() => changeLanguage('de')}
      >
        DE
      </Button>
    </ButtonGroup>
  );
}

export default LanguageSwitcher;
