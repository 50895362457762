import React from 'react';
import './LandingPage.css';
import TrustedUs from '../TrustedUs';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';


function  LandingPage() {
  const { t } = useTranslation();
  return (
    <>
      <Container className="my-0">
        <Row className="text-center my-0 gx-4 gy-2 d-flex justify-content-center">
          <Col xs={16} md={4} >
          <Link to="/certyfikaty" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Container>
              <Row className="my-0 mx-0 px-0 pt-3 pb-0 gx-0 justify-content border rounded brightness2">
                <Col xs={7} md={7} >
                  <ul className="oldr-font">
                    <li>EN 1090-1:2009+A1:2011</li>
                    <li>EN ISO 3834-2:2021</li>
                  </ul>
                </Col>
                <Col xs={4} md={4} >
                  <Image src={require('../../static/certs/tuv.png')} alt="tuv-logo" rounded width="70%"/>
                </Col>
              </Row>
            </Container>
          </Link>
            <br/>
            <p>{t('opis-p1')}</p>
            <p>{t('opis-p2')}</p>
            <p>{t('opis-p3')}</p>
          </Col>
          <Col xs={16} md={8} >
            <Image src={require('../../static/base-left.jpg')} alt="gpz-montage-side" rounded width="100%"/>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <Image src={require('../../static/base-bottom.jpg')} alt="gpz-montage-bottom" rounded width="100%"/>
      </Container>
      <TrustedUs />
    </>
  );
};

export default LandingPage;