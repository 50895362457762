import React from 'react';
import './Footer.css';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


function Footer() {
  const { t } = useTranslation();

    return (
      <>
        <Navbar bg="light" variant="light" expand="lg">
        <Container>
          <Navbar.Brand>
            <img
                  src={require('../../static/logo2.jpg')}
                  width="224"
                  height="240"
                  className="d-inline-block align-top"
                  alt="Logo"
              />{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="w-100 mx-10 px-5 my-5 py-2">
                <Row className="w-100">
                  <Col xs={10} md={6}>
                    <Nav.Link href="/">{t('o-firmie')}</Nav.Link>
                    <Nav.Link href="/certyfikaty">{t('certyfikaty')}</Nav.Link>
                    <Nav.Link href="/park-maszynowy">{t('maszyny')}</Nav.Link>
                  </Col>
                  <Col xs={10} md={6}>
                    <Nav.Link href="/realizacje">{t('realizacje')}</Nav.Link>
                    <Nav.Link href="/kontakt">{t('kontakt')}</Nav.Link>
                    <Nav.Link href="/polityka-prywatnosci">{t('polityka')}</Nav.Link>
                  </Col>
                </Row>
              </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="footer">
        <p>Copyright © {new Date().getFullYear()} - GPZ Montage Sp. z o.o.</p>
      </div>
    </>
    );
}

export default Footer;
