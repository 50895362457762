import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


function ContactPage() {
  const { t } = useTranslation();

  return (
    <Container className="my-3 gx-0" >
      <h2 className="text-center">{t('kontakt')}</h2>
      <Row className="text-center my-3">
        <Col xs={12} md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <p><strong>GPZ Montage Sp. z o.o.</strong></p>
                <p><strong>Wspólna 2c</strong></p>
                <p><strong>32-300 Olkusz</strong></p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p><a href="mailto:biuro@gpzmontage.eu">biuro@gpzmontage.eu</a></p>
              </ListGroup.Item>
              <ListGroup.Item>
                <p><strong>{t('otwarcie-1')}</strong></p>
                <Row className="my-3">
                  <Col>
                  <p>{t('otwarcie-2-1')}</p>
                  </Col>
                  <Col>
                  <p>{t('otwarcie-2-2')}</p>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col>
                  <p>{t('otwarcie-3-1')}</p>
                  </Col>
                  <Col>
                  <p>{t('otwarcie-3-2')}</p>
                  </Col>
                </Row>

              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
        <Col xs={12} md={6}>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2549.3073612487924!2d19.536664200000004!3d50.28619020000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716dbffd843e865%3A0x8e44cfa815e4187a!2sGPZ%20Montage%20Sp.%20z%20o.o.!5e0!3m2!1spl!2spl!4v1717610761107!5m2!1spl!2spl"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowfullscreen
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade">
        </iframe>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactPage;
