import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


function PrivacyPage() {
  const { t } = useTranslation();

  return (
    <Container className="my-3 gx-0" >
      <h2 className="text-center">{t('polityka')}</h2>
    </Container>
  );
}

export default PrivacyPage;
