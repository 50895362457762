import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import NavBar from './components/common/NavBar';
import LandingPage from './components/landing/LandingPage';
import MachineParkPage from './components/MachineParkPage';
import ProjectsPage from './components/ProjectsPage';
import ContactPage from './components/ContactPage';
import CertificatesPage from './components/CertificatesPage';
import PrivacyPage from './components/PrivacyPage';
import Footer from './components/common/Footer';
import { Container } from 'react-bootstrap';
import './App.css';

function App() {
  return (
    <BrowserRouter>
        <div className="App">
          <NavBar/>
          <div className="content-wrapper">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/park-maszynowy" element={<MachineParkPage />} />
              <Route path="/realizacje" element={<ProjectsPage />} />
              <Route path="/kontakt" element={<ContactPage />} />
              <Route path="/certyfikaty" element={<CertificatesPage />} />
              <Route path="/polityka-prywatnosci" element={<PrivacyPage />} />
              <Route path="*" element={
                <Container className="my-3 gx-0" >
                  <h1  className="text-center"> 404 </h1>
                </Container>}
              />
            </Routes>
          </div>
          <Footer />
        </div>
    </BrowserRouter>
  );
}

export default App;
