import React from 'react';
import './NavBar.css';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';


function NavBar() {
  const { t } = useTranslation();
  return (
    <Navbar bg="light" variant="light" expand="lg" className="py-0">
      <Container>
        <Navbar.Brand href="/">
        <div class="brightness">
          <img
                src={require('../../static/logo-flat.png')}
                width="286"
                height="87"
                className="d-inline-block align-top"
                alt="Logo"
            />{' '}
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
              <Nav.Link href="/">{t('o-firmie')}</Nav.Link>
              <Nav.Link href="/certyfikaty">{t('certyfikaty')}</Nav.Link>
              <Nav.Link href="/park-maszynowy">{t('maszyny')}</Nav.Link>
              <Nav.Link href="/realizacje">{t('realizacje')}</Nav.Link>
              <Nav.Link href="/kontakt">{t('kontakt')}</Nav.Link>
          </Nav>
          <Nav className="ms-auto">
              <LanguageSwitcher />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;