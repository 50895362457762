import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './TrustedUs.css';
import { useTranslation } from 'react-i18next';


function TrustedUs() {
  const { t } = useTranslation();
  return (
    <Container className="my-5">
      <h2>{t('zaufali')}</h2>
      <Row className="text-center">
        <Col xs={6} md={4}>
          <img src={require('../static/trusted/budinvest.jpg')} alt="BUDINVEST Client" className="trusted-logo" />
        </Col>
        <Col xs={6} md={4}>
          <img src={require('../static/trusted/butting.jpg')} alt="BUTTING Client" className="trusted-logo" />
        </Col>
        <Col xs={6} md={4}>
          <img src={require('../static/trusted/fsstainless.png')} alt="FS STAINLESS Client" className="trusted-logo" />
        </Col>
        <Col xs={6} md={4}>
          <img src={require('../static/trusted/glatfelter.png')} alt="GLATFELTER Client" className="trusted-logo" />
        </Col>
        <Col xs={6} md={4}>
          <img src={require('../static/trusted/hartung.png')} alt="HARTUNG Client" className="trusted-logo" />
        </Col>
        <Col xs={6} md={4}>
          <img src={require('../static/trusted/iab.png')} alt="IAB Client" className="trusted-logo" />
        </Col>
        <Col xs={6} md={4}>
          <img src={require('../static/trusted/bss.png')} alt="BSS Client" className="trusted-logo" />
        </Col>
      </Row>
    </Container>
  );
}

export default TrustedUs;