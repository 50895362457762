import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function ProjectsPage() {
  const { t } = useTranslation();

  const paths = [
    { full: require('../static/projects/IMG_20180928_102053-full.webp'), thumb: require('../static/projects/IMG_20180928_102053.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/IMG_20181213_073548-full.webp'), thumb: require('../static/projects/IMG_20181213_073548.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/IMG_20181213_073745-full.webp'), thumb: require('../static/projects/IMG_20181213_073745.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/IMG_20190222_160232-full.webp'), thumb: require('../static/projects/IMG_20190222_160232.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/IMG_20190508_145808-full.webp'), thumb: require('../static/projects/IMG_20190508_145808.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/IMG_20190704_080134-full.webp'), thumb: require('../static/projects/IMG_20190704_080134.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/IMG_20190704_080527-full.webp'), thumb: require('../static/projects/IMG_20190704_080527.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/IMG_20190704_081005-full.webp'), thumb: require('../static/projects/IMG_20190704_081005.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.06.23-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.06.23.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.07.29-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.07.29.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.03 (1)-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.03 (1).webp'), xs: 12, md: 6 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.07.41-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.07.41.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.07.53-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.07.53.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.03-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.03.webp'), xs: 12, md: 6 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.08.42-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.08.42.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.04-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.04.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.04 (1)-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.04 (1).webp'), xs: 12, md: 6 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.05 (1)-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.05 (1).webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.05-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.05.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.05 (2)-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.05 (2).webp'), xs: 12, md: 6 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.33-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.33.webp'), xs: 12, md: 6 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.08.07-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.08.07.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.08.33-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.08.33.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.54-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.12.54.webp'), xs: 12, md: 6 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.13.13-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.13.13.webp'), xs: 12, md: 6 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.13.52-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.13.52.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.18.15-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.18.15.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.14.37-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.14.37.webp'), xs: 12, md: 6 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.15.01-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.15.01.webp'), xs: 12, md: 6 },
    { full: require('../static/projects/WhatsApp Image 2022-11-21 at 11.16.08-full.webp'), thumb: require('../static/projects/WhatsApp Image 2022-11-21 at 11.16.08.webp'), xs: 12, md: 6 },
    { full: require('../static/projects/new/1-Zdjęcie WhatsApp 2024-08-02 o 16.43.48_16a00a8a.jpg'), thumb: require('../static/projects/new/1-Zdjęcie WhatsApp 2024-08-02 o 16.43.48_16a00a8a.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/new/2-Zdjęcie WhatsApp 2024-08-02 o 16.44.24_4c65e4eb.jpg'), thumb: require('../static/projects/new/2-Zdjęcie WhatsApp 2024-08-02 o 16.44.24_4c65e4eb.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/new/3-Zdjęcie WhatsApp 2024-08-09 o 10.48.40_fe91f95b.jpg'), thumb: require('../static/projects/new/3-Zdjęcie WhatsApp 2024-08-09 o 10.48.40_fe91f95b.webp'), xs: 12, md: 6 },
    { full: require('../static/projects/new/4-Zdjęcie WhatsApp 2024-08-02 o 16.45.14_9f8f12a3.jpg'), thumb: require('../static/projects/new/4-Zdjęcie WhatsApp 2024-08-02 o 16.45.14_9f8f12a3.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/new/5-Zdjęcie WhatsApp 2024-08-09 o 10.51.06_fb6accca.jpg'), thumb: require('../static/projects/new/5-Zdjęcie WhatsApp 2024-08-09 o 10.51.06_fb6accca.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/new/6-Zdjęcie WhatsApp 2024-08-09 o 10.49.08_0dd838a6.jpg'), thumb: require('../static/projects/new/6-Zdjęcie WhatsApp 2024-08-09 o 10.49.08_0dd838a6.webp'), xs: 12, md: 6 },
    { full: require('../static/projects/new/7-Zdjęcie WhatsApp 2024-08-09 o 10.51.33_bf30bd13.jpg'), thumb: require('../static/projects/new/7-Zdjęcie WhatsApp 2024-08-09 o 10.51.33_bf30bd13.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/new/8-Zdjęcie WhatsApp 2024-08-09 o 10.52.39_d955aec9.jpg'), thumb: require('../static/projects/new/8-Zdjęcie WhatsApp 2024-08-09 o 10.52.39_d955aec9.webp'), xs: 6, md: 3 },
    { full: require('../static/projects/new/9-Zdjęcie WhatsApp 2024-08-09 o 10.52.58_f120e1eb.jpg'), thumb: require('../static/projects/new/9-Zdjęcie WhatsApp 2024-08-09 o 10.52.58_f120e1eb.webp'), xs: 6, md: 3 }
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(null);
  const [images, setImages] = useState(paths.map(path => ({ src: path.thumb })));

  useEffect(() => {
    if (isOpen && photoIndex !== null) {
      const updatedImages = [...images];
      updatedImages[photoIndex] = { src: paths[photoIndex].full };
      setImages(updatedImages);
    }
  }, [isOpen, photoIndex]);

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  return (
    <Container className="my-3">
      <h2 className="text-center">{t('realizacje')}</h2>
      <Row className="text-center my-3 gx-1 gy-1">
        {paths.map((path, index) => (
          <Col xs={path.xs} md={path.md} key={index}>
            <LazyLoadImage
              src={path.thumb}
              width="100%"
              effect="blur"
              onClick={() => openLightbox(index)}
              style={{ cursor: 'pointer' }}
              alt={`Project ${index + 1}`}
            />
          </Col>
        ))}
      </Row>
      {isOpen && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={images}
          index={photoIndex}
          on={{ view: ({ index }) => setPhotoIndex(index) }}
          plugins={[Zoom]}
          zoom={{ maxZoomPixelRatio: 3, scrollToZoom: true }}
          styles={{
            container: {
              backgroundColor: 'rgba(0, 0, 0, 0.9)',
            },
            header: {
              color: '#fff',
            },
            footer: {
              color: '#fff',
            },
            caption: {
              color: '#fff',
            },
            navigation: {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              color: '#000',
            },
          }}
        />
      )}
    </Container>
  );
}

export default ProjectsPage;
